// techbarn js is in shop.js

// Start TOTO JS - as of 12/1/21
// edited to keep only what affects header and footer as much as possible


"use strict"
var languagesInfo = {
    languages: ["en", "es", "fr"],
	urls: ["http://www.totousa.com", "http://esla.totousa.com", "http://frca.totousa.com"],
	names: ["ENGLISH", "ESPAÑOL", "FRANÇAIS"],
	hovers: ["Please select your language of preference.", "Por favor confirme su idioma de preferencia.", "Veuillez choisir votre langue."]
}

var mobile_breakpoint = 960;
var tablet_breakpoint = 1200;
var is_mobile = window.innerWidth <= mobile_breakpoint;
var is_tablet = window.innerWidth <= mobile_breakpoint;

var __skippedSlides = [];

$.arrayFlip = function(arr){
    var key, tmp_ar = {};
    for ( key in arr ){
        if ( arr.hasOwnProperty( key ) ){
            tmp_ar[arr[key]] = key;
        }
    }
    return tmp_ar;
}

jQuery(document).ready( function($) {

	$('header .menu-sub-item.on').closest('.menu-item.page').addClass('on');

	initTabs();

	$(document).on('click', '.user-locale', chooser);
	$(document).on('click', function(e){
		var container = $("header .topbar");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$("header .topbar .menu > ul > li").removeClass("on");
			$('header .topbar-push').removeClass('on');
			$('header .topbar-push').stop().slideUp();
		}
	});

	$('#custom_module_totopro .accordion-title').each(function() {
		var element = $(this);
		element.on('click', function() {
			if (!element.TotoPro_module)
				element.TotoPro_module = new TotoPro_module(element.next())
			}
		);
	});
	$('header .topbar .menu > ul > li').on('click', function() {
		if ($(this).hasClass("on")) {
			$(this).removeClass('on');
			$('header .topbar-push').removeClass('on');
			$('header .topbar-push').stop().slideUp();
		} else {
			$('header .topbar .menu > ul > li').removeClass('on');
			$(this).addClass('on');
			if ( $(this).find('ul.pushmenu').length > 0 ) {
				$('header .topbar-push').html('<ul>'+$(this).find('ul.pushmenu').html()+'</ul>').stop().slideDown();
				$('header .topbar-push').addClass("on");
			}
		}
	});
	$('header .topbar .menu > ul > li > ul').on('mouseleave', function() {
		$('header .topbar .menu > ul > li').removeClass('on');
		if ($('header .topbar-push').hasClass("on")) {
			$('header .topbar-push').removeClass('on');
			$('header .topbar-push').stop().slideToggle();
		}
	});
	$('header .topbar-push').on('mouseleave', function() {
		$('header .topbar-push').removeClass('on');
		$('header .topbar-push').stop().slideToggle();
		$('header .topbar .menu > ul > li').removeClass('on');
	});

	$('header nav > .nav > li').on('click', function(e) {
		if (!$(this).hasClass('on')) {
			e.preventDefault();
			e.stopPropagation();
			$('header nav > .nav > li').removeClass('on');
			$(this).addClass('on');
		} else {
			$(this).removeClass('on');
		}
	});
	$('header').on('mouseleave', function() {
		$('header nav > .nav > li').removeClass('on');
	});
	$('.top-links > .nav > li').on('click', function() {
		if (!$(this).hasClass('on')) {
			e.preventDefault();
			e.stopPropagation();
			$('.nav > li').removeClass('on');
			$(this).addClass('on');
		} else {
			$(this).removeClass('on');
		}
	});
	$('.top-links > .nav > li').on('mouseleave', function() {
		$('.top-links > .nav > li').removeClass('on');
	});
	$('.top-links > .language ul').on('click', function() {
		if (!$(this).hasClass('on')) {
			e.preventDefault();
			e.stopPropagation();
			$('.top-links > .language ul > li').removeClass('on');
			$(this).addClass('on');
		} else {
			$(this).removeClass('on');
		}
	}).on('mouseleave', function() {
		$(this).removeClass('on');
	});
	$(document).on("click", ".search-filters h3", function() {
		$(this).toggleClass("on").next("ul").toggleClass("on");
	});
	$('header nav > .nav > li').each(function() {
		if ($(this).find('.sub').length > 0) {
			$(this).find('span.link').append('<span class="arrow"></span>');
		}
	});

	build_global_footer();


	$('.accordion-title').on('click', function() {
		$(this).parent('li').toggleClass('on');
	});

	$('.trigger', 'footer').on('click', function() {
		$('footer').toggleClass('on');
		$("html,body").animate({
			scrollTop : $("body>.wrapper").outerHeight() + 900
		}, 800);
	});

	if ($('.dark-tabs').length > 0) {
		var scrollmagic_controller = new ScrollMagic.Controller();
		$(window).scrollTop(0);

		// Pin tabs to top
		var sm_dark_tabs = new ScrollMagic.Scene({
			triggerElement: '.dark-tabs .tabs',
			triggerHook: 0,
			duration: $('.dark-tabs').height() - $('.dark-tabs .tabs').height(),
			offset: -1 * $('header').height()
		}).setPin('.dark-tabs .tabs', {
			pushFollowers: false
		}).setClassToggle('.dark-tabs .tabs', 'pinned').addTo(scrollmagic_controller);

		$('.dark-tabs img').each(function(){
			if ($(window).width() >= 960) {
				$(this).on('load', function(){
					sm_dark_tabs.duration($('.dark-tabs').height() - $('.dark-tabs .tabs').height());
				});
			}
		});

		$(window).on('resize', function(){
			if ($(window).width() >= 960) {
				sm_dark_tabs.duration($('.dark-tabs').height() - $('.dark-tabs .tabs').height());
			}
		});

		setTimeout(function(){
			$(window).scrollTop(0);
		}, 1000);
	}

	$('header .menu-item').each(function(){
		if ($(this).find('.dropdown').length > 0) {
			$(this).addClass('has-dropdown');
		}
	});

	loadTabsHash();

}).on('mouseenter', 'header .menu-item.has-dropdown', function(e) {
	$('header').addClass('overlay');

}).on('mouseleave', 'header .menu-item.has-dropdown', function(e) {
	$('header').removeClass('overlay');
}).on('click', 'footer .close', function(e) {
	if ($('footer').hasClass('open')) {
		$('footer .footer-section:not(.static)').slideUp(1000);
		$('footer .close .x').html('+');
		$('footer .close .label').html('Open');
	
	} else {
		$('footer .footer-section:not(.static)').slideDown(1000);
		$('footer .close .x').html('&times;');
		$('footer .close .label').html('Close');
	
	}
	$('footer').toggleClass('open', !$('footer').hasClass('open'));

}).on('click', 'footer .column .heading', function(e) {
	$(this).parent().toggleClass('on');

}).on('click', 'footer .side-list .title', function(e) {
	e.preventDefault();
	$(this).toggleClass('on');

}).on('click', 'header .mobile-menu-toggle', function(e) {
	$('header').toggleClass('mobile-on');

}).on('click', 'header .menu-item.search .box', function(e) {
	e.preventDefault();
	if (e.target.closest('form')) {
		return false;
	}
	if ($(window).width() > 1200) {
		$('header .menu-item.search').toggleClass('on');
		if ($('header .menu-item.search').hasClass('on')) {
			$('header .menu-item.search input').focus();
		}
	} else {
		$('header form.searchbox').submit();
	}

}).on('click', 'header .menu-item.mega li.has-submenu > a', function(e) {
	if (is_tablet) {
		e.preventDefault();
		$(this).closest('.has-submenu').toggleClass('open');
	}
}).on('mouseenter', 'header .menu-item.mega li', function(e) {
	e.preventDefault();
	var el = $(this).closest('li');
	if(el.hasClass('on')) return;
	el.closest('.mega').find('li.on').removeClass('on');
	if(el.hasClass('has-submenu')) el.addClass('on');
}).on('mouseleave', 'header .menu-item.mega .submenu-wrap, header .menu-item.mega', function(e) {
	e.preventDefault();
	var mega = $(this).hasClass('mega') ? $(this) : $(this).closest('.mega');
	var subm = mega.find('.submenu-wrap');
	var firstLi = subm.find('ul li').first();
	if(!firstLi.hasClass('on')){
		subm.find('li.on').removeClass('on');
		if(firstLi.hasClass('has-submenu')) firstLi.addClass('on');
	}
}).on('click', function(e) {
	if (!e.target.closest('.menu-item.search')) {
		$('header .menu-item.search').removeClass('on');
	}
});

/* End Doc Rdy */

function loadTabsHash(){
	var hash = window.location.hash.substring(1),
		tab = hash && hash.indexOf('=') < 0  && $('.tabs-nav a[href=#'+hash+']').length ? $('.tabs-nav a[href=#'+hash+']') : null;

	if(tab && !tab.hasClass('active')){
		var par = tab.closest('.tabs-nav');
		$('a', par).removeClass('active');
		$("~ .tab-content", par).removeClass('active').hide();
		tab.addClass('active');
		$("~ .tab-content."+hash, par).addClass('active').fadeIn();
	}
}

window.addEventListener('popstate', function(e){
	loadTabsHash();
});

function initTabs(){
	$(".tabs-nav a").first().addClass("active");
	$(".tabs-nav").next().addClass("active");
	$(document).on("click", ".tabs-nav a:not(.active)", function(e){
		e.preventDefault();
		$(".tabs-nav a.active").removeClass('active');
		$(".tabs-nav ~ .tab-content").removeClass('active').hide();
		var ll = window.location.href.split('#')[0],
			href = $(e.target).attr("href").substring(1);
		window.history.pushState(href, document.title, ll+'#'+href);
		$(e.target).addClass("active");
		$(".tabs-nav ~ .tab-content."+href).addClass('active').fadeIn();

	});
}


function chooser(){
	var currKey = $.arrayFlip(languagesInfo.languages)[languse],
		modal = $('<div class="lang-modal OneLinkNoTx OneLinkKeepLinks"><div class="logo"></div><div class="hovers">'+languagesInfo.hovers[currKey]+'</div><div class="lang-chooser"></div></div>');
		languagesInfo.names.forEach(function(name, k){
			var lang = $('<a href="'+languagesInfo.urls[k]+'">'+capitalize(name)+'</a>');
            if(currKey == k) lang.addClass('active');
			lang.on('mouseenter', function(){
				$('.lang-modal .hovers').html(languagesInfo.hovers[k]);
			});
			modal.find('.lang-chooser').append(lang);
		});
	$.featherlight(modal, {variant: "lang-modal-wrap"});
}

function build_global_footer() {
	var container = $('footer .footer-top ul.nav .sub > ul.nav');
	var li = $(container).find('> li.big');
	var maxLinks = 4; // Number of max links per column
	var maxLi = 5; // Number of max columns
	var numLi = $(li).length;
	if ( numLi < maxLi ) {
		for ( var i = 0; i < maxLi - numLi; i++ ) {
			$('footer .footer-top ul.nav .sub > ul.nav > li.big').each(function() {
				checkLi($(this), maxLinks);
			});
		}
	}
}

function checkLi(li, maxLinks) {
	var links = $(li).find('.sub > ul.nav > li', li);
	var numLinks = $(links).length;
	if ( numLinks > maxLinks ) {
		var moveLinks = $(links).slice(maxLinks);
		var newLi = $('<li class="big"></li>');
		var newSub = $('<div class="sub"></div>');
		var newUl = $('<ul class="nav"></ul>');
		$(newUl).append(moveLinks);
		$(newSub).append(newUl);
		$(newLi).append('<a><span class="link"></span></a>');
		$(newLi).append(newSub);
		$('footer .footer-top .wrapper > .international > ul.nav > li > .sub > ul.nav').append(newLi);
	}
}

function resetBoxHeight(container, element) {
	var h = 0;
	$(element, container).css('height', 'auto');
	$(element, container).each(function() {
		if ($(this).height() > h) {
			h = $(this).outerHeight();
		}
	});
	$(element, container).height(h);
}

function go2(element) {
    element = $(element);
    if (!element.length) return false;
    var headerH = $('header').outerHeight(),
        top = element.offset().top - headerH - 20;
    $('html,body').animate({
        scrollTop: top
    });
}

function capitalize(string){
    var lower = string.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.substr(1);
}







// End TOTO JS
