document.addEventListener('turbolinks:load', function() {
	ShopView.init();
	ProductForm.init();
	Cart.init();
	Product.init();
	WarningModal.init();
	$('[data-toggle="tooltip"]').tooltip();
	
	$(document).ready(function() {

		$('.store-nav.sub-nav').each(function() {
			if($(this).attr("href") === window.location.pathname) {
				$(this).addClass('selected')
			}
		})
		if(window.location.pathname === '/store') {
			$('#all').addClass('selected')
		}

		$('#psearch').on('input', function() {
			if ($(this).val().length)
				$('#search').prop('disabled', true);
			else
				$('#search').prop('disabled', false);
		});

		$('#search').on('input', function() {
			if ($(this).val().length)
				$('#psearch').prop('disabled', true);
			else
				$('#psearch').prop('disabled', false);
		});

		// to rescue bad scene 7 urls
		// $('.part-img').on("error", function() {
		// 	$(this).attr('src', "assets/toto_logo.jpg");
		// })

		// disable checkout process buttons to prevent user from clicking twice
		$("#continue_button").click(function () {
    		setTimeout(function () { disableButton($(this)); }, .5);
		});
		function disableButton() {
    		$("#continue_button").prop('disabled', true);
    		$("#continue_button").text('Processing...')
		}

	})        
      	
});


var Reqs = {
	throttle: function(fn, threshhold, scope) {
		threshhold || (threshhold = 250);

		var last,
			deferTimer;

		return function () {
			var context = scope || this;

			var now = +new Date,
				args = arguments;

			if (last && now < last + threshhold) {
				// hold on to it
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function () {
					last = now;
					fn.apply(context, args);
				}, threshhold);
			} else {
				last = now;
				fn.apply(context, args);
			}
		}
	},
};

ShopView = {
	init: function() {
		$('#trip_position_all').prop('checked', true);

		$('#trip_position_all').on('click', function() {
			$('#left_hand_trip_levers').show();
			$('#right_hand_trip_levers').show();
			$('#top_mount_trip_levers').show();
			$('#front_mount_trip_levers').show();
			$('#other_trip_levers').show();
		});

		$('#trip_position_left').on('click', function() {
			$('#left_hand_trip_levers').show();
			$('#right_hand_trip_levers').hide();
			$('#top_mount_trip_levers').hide();
			$('#front_mount_trip_levers').hide();
			$('#other_trip_levers').hide();
		});

		$('#trip_position_right').on('click', function() {
			$('#right_hand_trip_levers').show();
			$('#left_hand_trip_levers').hide();
			$('#top_mount_trip_levers').hide();
			$('#front_mount_trip_levers').hide();
			$('#other_trip_levers').hide();
		});

		$('#trip_position_top').on('click', function() {
			$('#top_mount_trip_levers').show();
			$('#left_hand_trip_levers').hide();
			$('#right_hand_trip_levers').hide();
			$('#front_mount_trip_levers').hide();
			$('#other_trip_levers').hide();
		});

		$('#trip_position_front').on('click', function() {
			$('#front_mount_trip_levers').show();
			$('#left_hand_trip_levers').hide();
			$('#right_hand_trip_levers').hide();
			$('#top_mount_trip_levers').hide();
			$('#other_trip_levers').hide();
		});
	}
};

ProductForm = {
	init: function() {
		$('body').on('click', '.js-productForm-submit', function(e) {
			var $form = $(this).closest('form.productForm');
			e.preventDefault();
			if ($form.find('[type="file"]').length) return;
			ProductForm.submit($(this));
		});
	},
	submit: function(el) {
		var $form = el.closest('form.productForm'),
			product_handle = el.attr("data-handle"),
			variant_id = $form.find('[name="id"]').val(),
			form_array = $form.serializeArray();

		var form_data = {};

		$.map(form_array, function(val, i){
		  form_data[val.name] = val.value;
		});

		$.ajax({
			method: 'POST',
			url: '/line_item',
			dataType: 'json',
			data: form_data,

			success: function(product){
				// just reload the page yo
				location.reload();
			},
			// If there are no products in the inventory
			error: function(data){
				$.ajax({
					method: 'GET',
					url: '/products/'+product_handle+'.js',
					dataType: 'json',
					success: function(product){
						var variants = product.variants,
							// variants is an array [0{},1{},2{}...]
							variant = $.each(variants, function(i, val){
								// val returns the contents of 0,1,2
								if (val.id == variant_id) {
									return variant_quantity = val.inventory_quantity; // set variant_quantity variable
								}
							}),
							$popover = $('#CartPopoverCont'), // same popover container used to show succesful cart/add.js
							error_details = window.theme.translations.form_submit_error_details, // translation string
							tag = new RegExp('\[\[i\]\]'), // checks for [[i]]
							error = error_details; // set error to just default to error_details

						if (tag.test(error_details) == true){
							// [[i]] is part of the trans string, this can be whatever,
							// but in the tutorial we use [[i]] so leave it
							error = error_details.replace('[[i]]', variant_quantity); // re-set error with new string
						}

						el.html( window.theme.translations.form_submit_error ); // swap button text
						setTimeout(function(){
							el.html( window.theme.translations.form_submit ); // swap it back
						}, 1000);

						// clear popover timer, set at top of Cart object
						clearTimeout(popoverTimer);

						// empty popover, add error (with inventory), show it, remove click events so it doesn't open cart
						$popover.empty().append('<div class="popover-error">'+error+'</div>').addClass('is-visible').css({'pointer-events': 'none'});
						// set new instance of popoverTimer
						popoverTimer = setTimeout(function(){
							$popover.removeClass('is-visible').css({'pointer-events': 'auto'});
						}, 5000);
					},
					error: function(){
						console.log("Error: product is out of stock. If you're seeing this, ProductForm.submit.error() must have failed.");
					}
				});
			}
		});
	},
};


Cart = {
	init: function(){
		var self = this,
			$cart = $('#Cart');

		$cart.on( 'blur', '.inputCounter', function() {
			console.log($(this).val())
			var el = $(this),
				value = $(this).val();

			self.inputCounter( el, value );
		});

		$cart.on('click', '.inputCounter-down', function(){
			var el = $(this),
				$input = el.siblings( '.inputCounter' ),
				value = parseInt( $input.val() ) - 1;

			self.inputCounter(el, value);
		}).on('click', '.inputCounter-up', function(){
			var el = $(this),
				$input = el.siblings( '.inputCounter' ),
				value = parseInt( $input.val() ) + 1;

			self.inputCounter(el, value);
		});

		/* Remove line item on x click */
		$cart.on( 'click', '.cart-product-remove', function() {
			var el = $(this);
			self.inputCounter(el, 0);
		});
	},

	inputCounter: function( el, value ) {
		var self = this,
			$cart = $( '#Cart' ),
			$input = el.closest( '.line-item' ).find( '.inputCounter' ),
			$msg = el.siblings('.crtmsg');

		prev = parseInt($input.attr('data-value'));
		max = parseInt($input.attr('data-inventory'));

		// Prevent a negative quantity
		if (value < 0) {
			value = 0;
		}

		// no value > max from inventory
		if (value > max) {
			$msg.text('The maximum quantity available to order is ' + max);
			$msg.show();
			value = prev;
		} 
		else {
			$msg.hide();
		}

		var qty = value;
		var id = $input.attr( 'id' );
		var line_total = el.closest( '.line-item' ).find( '.cart-product-total-price' );
		var cart_total = 0;
		$.ajax( {
			async: 'false',
			type: 'put',
			url: '/line_item/' + id + '/change',
			dataType: 'json',
			data: {
				'item[quantity]': qty
			},
			beforeSend: function(xhr) {
				xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		}).done(function(data) {
			$.each( data, function( key, val ) {
				if(key == 'cart_subtotal') {
					$('#cart-total-display').text(val.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
				}
				if(key == 'line_item_total') {
					$(line_total).text(val.toLocaleString("en-US", {style: "currency", currency: "USD", minimumFractionDigits: 2}));
				}
				if(key == 'cart_quantity_total') {
					cart_total = val;
					if(val >= 0) {
						$( '#cart-total' ).text(val)
					}
				}
			});			
		});

		// remove line item if the quantity is 0
		if ( qty == 0 ) {
			$input.closest( '.line-item' ).fadeOut( function() {
				$( this ).remove();
				// self.updateCartTotal(cart_total);
			} );
		} else {
			$input.val(qty);
		}
	},
};

var Product = {
	selectors: {
		productSection: '[data-section-type="product"]',
		productThumbnail: '.js-product-single__thumbnail',
		productImage: '.product-image',
		productLayoutScrollable: '.product-layout--scrollable',
		productScrollerNav: '.js-productImgScroller-nav',
		productMediaThumb: '.product-single__media-thumb',
	},

	init: function(){
		var self = this,
			// update = (typeof update !== 'undefined') ? update : false;
			update = true;

		$( this.selectors.productSection ).each( function () {
			var container = $(this);

			self.initScroller( container, update );
		});
	},

	initScroller: function( container, update ) {
		var self = this,
			productImages = $( self.selectors.productImage, container ).length;

		if (productImages > 1) {
			var isPageLoaded = false,
				$productLayoutScrollable = $( self.selectors.productLayoutScrollable, container ),
				$productImg = $( self.selectors.productImage, container ),
				$productImgNav = $( self.selectors.productScrollerNav, container );

			// Adjust thumbnails position if change setting in TE
			if ( update ) stickyThumbnails();

			$(window)
				.on( 'scroll.product', Reqs.throttle( stickyThumbnails, 10 ) )
				.on( 'load', function() {
					isPageLoaded = true;
				});

			// Bind product thumb image click to scroll function
			$productImgNav.on( 'click', self.selectors.productThumbnail, function(e) {
				e.preventDefault();

				var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

				// if (windowWidth >= 768) {
					var id = $(this).parent().data( 'id' ),
						$selectedImage = $( self.selectors.productImage + '[data-id="' + id + '"]'),
						headerHeight = 59,
						additionalSpace = 40;

					if ( $selectedImage.length ) {
						var mediaId = $selectedImage.data( 'media-id' );
						if ( isPageLoaded || update ) {
							$('html, body')
								.stop( true, false )
								.animate({ scrollTop: $selectedImage.offset().top - headerHeight - additionalSpace }, 500, function() {
									// Product.switchMedia( container, mediaId )
								});
						}
					}
				// }
			});

			function stickyThumbnails() {
				var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

				if (windowWidth >= 768) {
					var scrolled = $(window).scrollTop(),
						productOffset = $productLayoutScrollable.offset().top - 100;

					for ( var i = $productImg.length - 1; i >= 0; i--) {
						var $currentProduct = $( self.selectors.productImage ).eq( i ),
							id = $currentProduct.data( 'id' ),
							productOffset = parseInt( $currentProduct.offset().top - 100 );

						if (scrolled >= productOffset) {
							$productImgNav.find( self.selectors.productMediaThumb + '[data-id="' + id + '"]').addClass( 'active' ).siblings().removeClass( 'active' );
							break;
						}
					}
				}
			}
		}
	}
};

WarningModal = {
	init: function() {
		$('.prop65-link').on('click', function(e) {
			$('#prop65Modal').show()
		});
		$('.close-modal').on('click', function(e) {
			$('#prop65Modal').hide()
		});
	}
}
